.legalDocsWindow {
    padding: 1rem 0;

    &__details {
        height: 100%;
        padding: 1rem;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 20px;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    p,
    ul {
        font-size: 16px;
        line-height: 1.5;
    }

    ul {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        padding-left: 1.5rem;

        li {
            margin-bottom: 0.5rem;
        }
    }
}

@media screen and (max-width: 1300px) {
    .legalDocsWindow {
        &__details {
            width: 90%;
        }
    }
}

@media screen and (max-width: 859px) {
    .legalDocsWindow {
        width: calc(90vw - 2rem);
    }
}