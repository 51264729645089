.startMenu {
    position: relative;
    width: 115px;

    &__slide {
        position: absolute;
        bottom: 31px;
        overflow: hidden;
        max-height: 0;

        &.-isOpen {
            max-height: 200px;

            .startMenu__menu {
                transform: translateY(0);
                transition: 0.1s;
            }
        }
    }

    &__menu.startMenu__menu {
        padding-left: 10px;
        transform: translateY(100%);

        &:before {
            position: absolute;
            width: 25px;
            height: calc(100% - 4px);
            top: 1px;
            left: 1px;
        }

    }

    &__triggerButton {
        margin-bottom: 3px;
        text-shadow: -1px 0 hsla(0, 0%, 0%, 0.7);
        font-size: 13px;
    }

    &__logo {
        position: relative;
        top: -1px;
        left: -1px;
        width: 17px;
        padding-right: 5px;
        image-rendering: pixelated;
        image-rendering: -moz-crisp-edges;
        image-rendering: crisp-edges;
        pointer-events: none;
    }

    &__menuItem {
        font-size: 12px !important;
        padding: 0.15rem 1rem 0 0.25rem;

        &.-soundItem {
            padding-right: 0;

            >span {
                position: relative;
                top: -2px;
                color: #000 !important;
            }
        }

        >img {
            position: relative;
            top: 9px;
            margin-right: 0.5rem;
        }
    }
}