.appsWindow {
    padding: 1rem 0;

    &__details {
        height: 100%;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        width: 80%;
        margin-right: auto;
        margin-left: auto;

        >.app-image {
            width: 50%;
            max-height: 100%;
            height: auto;
            object-fit: contain;
            margin-bottom: 2rem;
        }

        >.app-description {
            width: 100%;
            text-align: left;

            >p {
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 1rem;
            }
        }
    }
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__canvas {
    height: 40vh;
}

.react-pdf__Document {
    max-height: 30vh;
}

@media screen and (max-width: 1300px) {
    .appsWindow {
        &__item {
            width: 90%;

            >.app-image {
                width: 75%;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            >.app-description {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 859px) {
    .appsWindow {
        width: calc(90vw - 2rem);
    }
}