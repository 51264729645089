.taskbar.taskbar {
    position: absolute;
    bottom: 0;
    top: auto;
    z-index: 3;
}

.taskbar {
    &__toolbar.taskbar__toolbar {
        padding-right: 55px;
    }

    &__buttonWrapper {
        display: flex;
        flex: 1;
    }

    &__applications {
        flex: 1;
        overflow: auto;
    }

    &__applicationsButtons {
        position: absolute;
        left: 90px;
        right: 0;
    }
}



.taskbarLanguage {
    margin-right: 16px; // Adjust this value as needed for spacing
    height: 25px;
    padding: 0 0.5rem;
    font-size: 1rem;
    line-height: 28px;
    border: 2px inset #ededed;
    background: transparent;
    color: black;
    cursor: pointer;
}

// Add some padding or margin to the right of the language button for spacing
.taskbarLanguage {
    margin-right: 30px; // Adjust this value to increase or decrease spacing
}



.taskbarClock {
    position: absolute;
    right: 4px;
    height: 25px;
    line-height: 28px !important;
    margin: 0.25rem;
    padding: 0 0.25rem;
    border: 2px inset #ededed;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.taskbarButton.taskbarButton {
    height: 30px;
    max-width: 150px;

    >div {
        width: 100%;
    }
}

.taskbarButton {
    &__label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 1.25rem;
    }

    &__icon {
        position: absolute;
        image-rendering: pixelated;
        image-rendering: -moz-crisp-edges;
        image-rendering: crisp-edges;
        pointer-events: none;

        &.-about {
            margin-left: -4rem;
        }

        &.-team {
            margin-left: -3.75rem;
        }

        &.-legal {
            margin-left: -3.5rem;
        }

        &.-apps {
            margin-left: -3.5rem;
        }


    }

}

@media screen and (max-width: 859px) {
    .taskbarButton.taskbarButton {
        width: 10vw;
    }

    .taskbarButton {
        &__icon {
            &.-about {
                margin-left: 0rem;
            }


            &.-team {
                margin-left: 0rem;
            }

            &.-legal {
                margin-left: 0rem;
            }

            &.-apps {
                margin-left: 0rem;
            }

        }

    }
}