.teamWindow {
    &__body {
        height: 70vh;

        >h1 {
            font-size: 30px;
            padding-left: 1rem;
        }
    }

    &__bodyInner {
        padding: 1rem;
        width: 85%; // Increased width to make the window wider
        margin-left: auto;
        margin-right: auto;

        .teamMember {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            &__image {
                width: 120px;
                height: 120px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 30px;
            }

            &__info {
                h2 {
                    font-size: 22px;
                    margin-bottom: 0.3rem;
                }

                h3 {
                    font-size: 18px;
                    margin-bottom: 0.5rem;
                    color: #555;
                }

                p {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
        }
    }
}

@media screen and (max-width: 859px) {
    .teamWindow {
        width: calc(90vw - 2rem);
        height: auto;
    }

    .teamWindow__bodyInner {
        width: 100%;
    }

    .teamMember {
        flex-direction: column;
        align-items: center;
        text-align: center;

        &__image {
            margin-bottom: 1rem;
        }

        &__info {

            h2,
            h3 {
                text-align: center;
            }
        }
    }
}