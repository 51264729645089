.aboutWindow {
    &__body {
        >h1 {
            font-size: 30px;
            padding-left: 0.5rem;
        }
    }

    &__bodyInner {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 1rem;
        width: 90%;
        height: 100%;
        margin-left: auto;
    }
}

.profile-picture {
    width: 25%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
    margin-left: 2rem;
}

.description {
    font-size: 16px;
    line-height: 1.5;
}

@media screen and (max-width: 1300px) {
    .aboutWindow {
        &__body {
            height: 60vh;
        }

        &__bodyInner {
            flex-direction: column;
        }
    }

    .profile-picture {
        width: 40%;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 859px) {
    .aboutWindow {
        &__body {
            width: calc(90vw - 2rem);
        }
    }
}